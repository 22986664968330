export const handleMapping = {
  '1018-crs': {
    displayTitle: '1018 CRS',
    pageTitle: '1018 CRS',
    searchJobbossWith: '1018 CRS',
    replaceJobbossWith: '1018 CRS',
  },
  '1018-hrs': {
    displayTitle: '1018 HRS',
    pageTitle: '1018 HRS',
    searchJobbossWith: '1018 HRS',
    replaceJobbossWith: '1018 HRS',
  },
  '1045-hrs': {
    displayTitle: '1045 HRS',
    pageTitle: '1045 HRS',
    searchJobbossWith: '1045 HRS',
    replaceJobbossWith: '1045 HRS',
  },
  '17-4-cond-a': {
    displayTitle: '17-4 Cond A',
    pageTitle: '17-4 Cond A',
    searchJobbossWith: '17-4 COND A',
    replaceJobbossWith: '17-4 Cond A',
  },
  '17-4-h1150': {
    displayTitle: '17-4 H1150',
    pageTitle: '17-4 H1150',
    searchJobbossWith: '17-4 H1150 -',
    replaceJobbossWith: '17-4 H1150 -',
  },
  '17-4-h1150d': {
    displayTitle: '17-4 H1150D',
    pageTitle: '17-4 H1150D',
    searchJobbossWith: '17-4 H1150D -',
    replaceJobbossWith: '17-4 H1150D -',
  },
  '304l': {
    displayTitle: '304L',
    pageTitle: '304L SS',
    searchJobbossWith: '304 SS',
    replaceJobbossWith: '304L SS',
  },
  '316l': {
    displayTitle: '316L',
    pageTitle: '316L SS',
    searchJobbossWith: '316 SS',
    replaceJobbossWith: '316L SS',
  },
  '317': {
    displayTitle: '317',
    pageTitle: '317 SS',
    searchJobbossWith: '317 SS',
    replaceJobbossWith: '317 SS',
  },
  '410': {
    displayTitle: '410',
    pageTitle: '410 SS',
    searchJobbossWith: '410 SS',
    replaceJobbossWith: '410 SS',
  },
  '416': {
    displayTitle: '416',
    pageTitle: '416 SS',
    searchJobbossWith: '416 SS',
    replaceJobbossWith: '416 SS',
  },
  '420': {
    displayTitle: '420',
    pageTitle: '420 SS',
    searchJobbossWith: '420 SS',
    replaceJobbossWith: '420 SS',
  },
  '660-bronze': {
    displayTitle: '660 Bronze',
    pageTitle: '660 Bronze',
    searchJobbossWith: '660 BRONZE',
    replaceJobbossWith: '660 Bronze',
  },
  'alloy-20': {
    displayTitle: 'Alloy 20',
    pageTitle: 'Alloy 20',
    searchJobbossWith: 'ALLOY 20',
    replaceJobbossWith: 'Alloy 20',
  },
  'alloy-59': {
    displayTitle: 'Alloy 59',
    pageTitle: 'Alloy 59',
    searchJobbossWith: 'ALLOY 59',
    replaceJobbossWith: 'Alloy 59',
  },
  'alloy-400': {
    displayTitle: 'Alloy 400',
    pageTitle: 'Alloy 400',
    searchJobbossWith: 'ALLOY 400',
    replaceJobbossWith: 'Alloy 400',
  },
  'alloy-600': {
    displayTitle: 'Alloy 600',
    pageTitle: 'Alloy 600',
    searchJobbossWith: 'ALLOY 600',
    replaceJobbossWith: 'Alloy 600',
  },
  'alloy-718': {
    displayTitle: 'Alloy 718',
    pageTitle: 'Alloy 718',
    searchJobbossWith: 'ALLOY 718',
    replaceJobbossWith: 'Alloy 718',
  },
  'alloy-c-22': {
    displayTitle: 'Alloy C-22',
    pageTitle: 'Alloy C-22',
    searchJobbossWith: 'ALLOY C-22',
    replaceJobbossWith: 'Alloy C-22',
  },
  'alloy-c-276': {
    displayTitle: 'Alloy C-276',
    pageTitle: 'Alloy C-276',
    searchJobbossWith: 'ALLOY C-276',
    replaceJobbossWith: 'Alloy C-276',
  },
  'alum-6061': {
    displayTitle: 'Alum 6061',
    pageTitle: 'Alum 6061',
    searchJobbossWith: 'ALUM 6061',
    replaceJobbossWith: 'Alum 6061',
  },
  ca15: {
    displayTitle: 'CA15',
    pageTitle: 'CA15',
    searchJobbossWith: 'CA15',
    replaceJobbossWith: 'CA15',
  },
  ca40: {
    displayTitle: 'CA40',
    pageTitle: 'CA40',
    searchJobbossWith: 'CA40',
    replaceJobbossWith: 'CA40',
  },
  cd4mcun: {
    displayTitle: 'CD4MCuN',
    pageTitle: 'CD4MCuN',
    searchJobbossWith: 'CD4MCUN',
    replaceJobbossWith: 'CD4MCuN',
  },
  'cl40-cast': {
    displayTitle: 'CL40 Cast',
    pageTitle: 'CL40 Cast',
    searchJobbossWith: 'CL40 CAST',
    replaceJobbossWith: 'CL40 Cast',
  },
  'duplex-2205': {
    displayTitle: 'Duplex 2205',
    pageTitle: 'Duplex 2205',
    searchJobbossWith: 'DUPLEX 2205',
    replaceJobbossWith: 'Duplex 2205',
  },
  'duplex-255': {
    displayTitle: 'Duplex 255',
    pageTitle: 'Duplex 255',
    searchJobbossWith: 'DUPLEX 255',
    replaceJobbossWith: 'Duplex 255',
  },
  'zeron-100': {
    displayTitle: 'Zeron 100',
    pageTitle: 'Zeron 100',
    searchJobbossWith: 'ZERON 100',
    replaceJobbossWith: 'Zeron 100',
  },
  'zirconium-702': {
    displayTitle: 'Zirconium 702',
    pageTitle: 'Zirconium 702',
    searchJobbossWith: 'ZIRCONIUM 702',
    replaceJobbossWith: 'Zirconium 702',
  },
}
